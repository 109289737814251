// https://codepen.io/altitudems/pen/HLFKx
/* ================
   Assign Variables */
$primary-bg: #6eb590;
$secondary-bg: #9987b5;
$disabled-bg: #e0e6e8;
/* ===========================
   Setup Mixins/Helper Classes */
.clearfix:after {
  content: '.';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
@mixin tag__nummer {
  border-radius: 50%;
  width: 1.8em;
  height: 1.8em;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.2em;
}
/* ================
   Calendar Styling */

.kalender {
  margin: 1rem auto;

  .monat {
    margin-top: 0;
    font-weight: normal;
  }

  ol {
    list-style: none;
    margin: 0;
    padding: 0;
    @extend .clearfix;

    li {
      float: left;
      width: percentage(1/7);
      background-color: #f1f1f1;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%239b9b9b' fill-opacity='0.4' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
    }
  }

  .tagesnamen {
    border-bottom: 1px solid $gray-300;

    li {
      text-transform: uppercase;
      margin-bottom: 0.3em;
      padding-left: 0.2em;
      background: white;
    }
  }

  .tage {
    border-radius: 0.375rem;
    box-shadow: 0 15px 45px -5px rgba(10, 16, 34, 0.15);

    li {
      border-bottom: 1px solid $gray-300;
      min-height: 8em;
      padding: 0.3em;
      position: relative;
      display: flex;
      flex-direction: column;

      .tag__nummer {
        @include tag__nummer;
      }
      // Großes Pencil-Icon
      .btn--edit {
        display: block;
        z-index: 2;
        align-self: center;
        justify-self: center;
        position: absolute;
        top: 30%;
        &:hover {
          cursor: pointer;
        }
      }
      // Tage
      &.tag {
        &--kein-notdienst {
          background: $disabled-bg;
          &:hover {
            cursor: not-allowed;
          }
        }
        &--auswaehlbar {
          background: lighten($yellow, 40);
        }
        &--meiner {
          background: lighten($primary-bg, 30);
          .tag__nummer {
            background: darken($primary-bg, 10);
            color: wheat;
            font-weight: bold;
            @include bounceIn();
          }
        }
        &--fixiert {
          background: lighten($primary-bg, 20);
        }
        &--vergeben {
          background: lighten($red, 40);
          &:hover {
            cursor: not-allowed;
          }
          .tag__nummer {
            text-decoration: line-through;
          }
        }
      }

      /* #652 derzeit als invalid */
      // .urlaub-im-bezirk {
      //     position: relative;
      //     z-index: 1;
      //     display: inline-block;
      //     @extend .text-muted;
      //     &:hover {
      //         z-index: 3;
      //         i {
      //             @extend .text-info;
      //         }
      //     }
      // }

      dl {
        margin-bottom: 1px;
      }
      dt {
        font-weight: 400;
      }

      @at-root .kalender--admin .tag__event {
        font-size: 0.75em;
        margin-bottom: 1px;
        padding: 0.05rem 0.4rem;
        clear: right;
        background: $gray-400;
        border-radius: 1em;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        box-shadow: 0 0 0.1rem rgba($gray-600, 0.7);
        will-change: transform, opacity;
        &--keiner {
          background: $gray-600 !important;
          color: white;
        }

        &:hover {
          cursor: help;
          box-shadow: 0 0 0.15rem rgba($gray-600, 0.9);
          transform: scale(1.05);
          transition-duration: 0.3s;
        }
      }

      .bezirk {
        // Bludenz
        &--1 {
          background: $bezirk-bludenz;
        }
        // Bregenz
        &--2 {
          background: $bezirk-bregenz;
        }
        // Dornbirn
        &--3 {
          background: $bezirk-dornbirn;
        }
        // Feldkirch
        &--4 {
          background: $bezirk-feldkirch;
        }
      }

      &:nth-child(n + 29) {
        border-bottom: none;
      }

      &.outside .datum {
        color: $gray-500;
      }
    }
  }
}
