$enable-flex: true;
$input-btn-line-height: 1.35;
$fa-font-path: '../font';

// colors
$blue: #86cbd5;
$orange: hsl(24, 78%, 57%);
$navbar-light-color: #222;

// Mixins
@mixin hyphens($mode: auto) {
  word-wrap: break-word;
  hyphens: $mode;
}

@mixin chosen-container-single-custom($min-width: 200px) {
  min-width: $min-width;
}

// Farben der Bezirke
$bezirk-bludenz: #dce6f1; // Bludenz
$bezirk-bregenz: #81b6e3; // Bregenz
$bezirk-dornbirn: #a3d749; // Dornbirn
$bezirk-feldkirch: #91d1bf; // Feldkirch
