body {
  margin-top: 5rem;
}

h1 {
  margin-bottom: 1rem;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  hyphens: auto;
}

body > .container {
  display: flex;
  flex-flow: column;
  align-content: stretch;
  align-items: stretch;
  height: calc(100vh - 5rem);
  justify-content: flex-start;
  flex: 1;
}

main {
  flex: 1;
}

.navbar {
  &-custom {
    $brand: $blue;
    background-color: $brand;
    @extend .navbar-light;

    @at-root .staging & {
      $brand: $orange;
      background-color: $brand;
    }
  }
}

// cards mit einzelnen zentrierten Überschriften
.card {
  &.text-center {
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    will-change: transform, opacity;
    &::after {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      //@extend .shadow;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
      opacity: 0;
      transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    }
    &:hover {
      //@extend .shadow;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
      z-index: 1;
      &::after {
        opacity: 1;
      }
    }
  }
  &-title {
    margin-bottom: 0;
  }
}

// Formulare
.form-control {
  margin-right: 0.4rem;
}
.form-group {
  .form-control,
  .btn,
  .chosen-container {
    margin-right: 0.4rem;
  }
}

form {
  .chosen-container,
  label {
    margin-right: 0.4rem;
  }
  input[type='text'],
  input[type='password'] {
    @extend .form-control;
    margin-right: 0.4rem;
  }
}

/* #640 */
.urlaubstage {
  header {
    ul {
      margin-top: initial;
    }
  }
}
.urlaube {
  .chosen-container-single {
    @include chosen-container-single-custom;
  }
}

/* #644 Benutzergruppen */
.user-group {
  display: flex;
  justify-content: space-around;
  background: $gray-200;
  border-radius: $border-radius;
  padding: 0.2rem 0.4rem;
  a:last-child .fa {
    margin-right: 0 !important;
  }
}

// Exporte
.exporte {
  .card,
  fieldset {
    margin-bottom: 1rem;
  }
  .chosen-container-single {
    @include chosen-container-single-custom;
  }
}

// Vakanzen
.vakanzen {
  .chosen-container-single {
    @include chosen-container-single-custom;
  }
}

// CSS Grid
.grid {
  display: grid;
  grid-gap: 0.5rem;
  &--half {
    grid-template-columns: repeat(auto-fit, minmax(20em, 1fr));
    grid-template-rows: auto-fit;
  }
}

// Aktionen-Links mit Hand-Kursor
td.d-flex {
  a {
    cursor: pointer;
  }
}

// Icons
.fa {
  .btn--icon-text &,
  .card.text-center &,
  .footer &,
  .table & {
    margin-right: 0.4rem;
  }
}

// fix button size

form button {
  align-self: flex-start;
}

// Helpers

// Alter Browser (ohne cssgrid)? Meldung.
.alert--legacybrowser {
  display: none;
  visibility: hidden;

  .no-cssgrid & {
    display: block;
    visibility: visible;
  }
}

.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.4;
}

.pagination {
  margin: 1rem auto;
}
